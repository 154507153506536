import React, {Component} from 'react'
import {Badge, Container, Form, Row, Table} from 'react-bootstrap';
import './CCartEntry.css';
import {MdDelete} from 'react-icons/md';
import ApiV0 from '../services/ApiV0'
import CUtils from '../services/CUtils';
import CPrice from '../common/CPrice';
import CListingCustomizationValue from './CListingCustomizationValue';
import {withTranslation} from 'react-i18next';

import {decode} from 'html-entities';

class CCartEntry extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    state = {
        loaded: false,
        productLink: this.generateProductLink()
    };

    onClick() {
        document.location = this.state.productLink;
    }

    decodedTitle() {
        const lang = ApiV0.getLang();
        const label = lang === 'en' && this.props.product.en_label ? this.props.product.en_label : this.props.product.label;
        return decode(label);
    }

    getTitle(limit = 100) {
        return CUtils.generateShortTitle(this.decodedTitle(), limit);
    }

    generateImageUrl() {
        return this.props.product["url_570xN"];
        // return this.props.product.images[0]["url_170x135"];
    }

    generateProductLink() {
        return CUtils.generateProductLink(this.props.product.shop_section_id, this.props.product.listing_id, this.decodedTitle());
    }

    removeFromCart() {
        const {t} = this.props;
        const title = this.getTitle();
        var quantityToSet = 0;
        ApiV0.addToCart(this.props.product.listing_id, quantityToSet,
            this.props.product.variation_key, this.props.product.variation_value,
            this.props.product.customization_value)
            .then((cart) => {
                this.props.onCartUpdated(`1 ${title} ${t('cart.entry.removed')}`);
            })
            .catch((error) => {
                this.props.onCartError(`1 ${title} ${t('cart.entry.cantBeRemoved')}`);
            })
    }

    changeWantedQuantity(event) {
        const {t} = this.props;
        const title = this.getTitle();
        const wantedQuantity = event.target.value;
        console.info("changeWantedQuantity", wantedQuantity);
        ApiV0.addToCart(this.props.product.listing_id, wantedQuantity, this.props.product.variation_key, this.props.product.variation_value)
            .then((cart) => {
                this.props.onCartUpdated(`${wantedQuantity} ${title} ${t('cart.entry.inCart')}`);
            })
            .catch((error) => {
                this.props.onCartError(`${wantedQuantity} ${title} ${t('cart.entry.impossible')} : ${error}`);
            })
    }

    render() {
        const {t} = this.props;
        const lang = ApiV0.getLang();
        const listingId = this.props.product.listing_id;
        const hasVariations = this.props.product.variation_key && this.props.product.variation_value;
        const hasCustomization = this.props.product.customization_value;
        const variationValue = lang === 'en' && this.props.product.en_variation_value ? this.props.product.en_variation_value : this.props.product.variation_value;
        const currency = this.props.product.unit_price_code === 'EUR' ? "€" : this.props.product.unit_price_code;
        const quantity = this.props.product ? this.props.product.quantity : 0;
        const quantityMax = this.props.product ? this.props.product.quantityMax : 0;
        const quantityToSet = this.state.wantedQuantity ? this.state.wantedQuantity : Math.min(quantity, quantityMax);
        const isModeCart = this.props.mode === 'cart';
        const title = this.getTitle();

        const entryImage = (<img className="widthImage"
                                 alt="product"
                                 src={this.generateImageUrl()}
                                 onLoad={() => this.setState({loaded: true})}/>);

        const entryDetails = (<>
            {/* this.props.product.quantity > 1 ? (
              <span>Quantité: <span className="cproductQuantity">{this.props.product.quantity}</span><br/></span>
            ) :null */}

            <Table border="0" size="sm">
                <tbody>

                <tr>
                    <td colSpan="2" className="productTitle"><a href={this.state.productLink}><span
                        itemProp="name">{title}</span></a>&#160;
                        {hasVariations ? (<Badge bg="light"
                                                 className="chBadge">{/*this.props.product.variation_key: */}{variationValue}</Badge>) : null}
                    </td>
                </tr>
                {hasCustomization ? (
                    <tr>
                        <td colSpan="2"><CListingCustomizationValue value={this.props.product.customization_value}/>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td className="cartproductPriceLabel">{t('cart.entry.unitPrice')}:</td>
                    <td><CPrice price={this.props.product.unit_price_raw}
                                oldPrice={this.props.product.unit_price_base}
                                currency={currency}/></td>
                </tr>
                {isModeCart && quantity > quantityMax ? (
                    <tr>
                        <td colSpan="2"><i>{t('cart.entry.quantityStock', {quantity, quantityMax})}</i></td>
                    </tr>
                ) : null}
                {isModeCart && !hasCustomization && this.props.product.quantityMax > 1 ? (
                    <tr>
                        <td>{t('cart.entry.quantity')} :</td>
                        <td>
                            <Form.Select value={quantityToSet}
                                         onChange={this.changeWantedQuantity.bind(this)}
                                         style={{width: 70}}>
                                {[...Array(this.props.product.quantityMax).keys()].map((val, index) => {
                                    return (
                                        <option key={index} index={index} value={val + 1}>{val + 1}</option>)
                                })}
                            </Form.Select>
                        </td>
                    </tr>
                ) : null}
                {!isModeCart && quantity > 1 && quantityMax > 1 ? (
                    <tr>
                        <td>{t('cart.entry.quantity')} :</td>
                        <td>{quantityToSet}</td>
                    </tr>
                ) : null}

                {isModeCart ? (
                    <tr>
                        <td>&#160;</td>
                        <td>
                            <button className="btn-delete"
                                    onClick={this.removeFromCart.bind(this)}
                                    title={t('cart.entry.doRemoveFromCart')}>
                                <MdDelete/>{t('cart.entry.doRemoveFromCart')}
                            </button>
                        </td>
                    </tr>
                ) : null}
                </tbody>
            </Table>
        </>);

        return (

            <div itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" name={title}
                 key={this.props.index}
                 id={`card-${listingId}`}>
                <meta itemProp="position" content={this.props.index}/>
                <meta itemProp="url" content={this.generateProductLink()}/>
                <Container className="largeScreen">
                    <Row>
                        <table>
                            <tbody>
                            <tr>
                                <td className="widthImageCell">
                                    <div onClick={this.onClick.bind(this)}>{entryImage}</div>
                                </td>
                                <td>
                                    {entryDetails}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Row>
                </Container>
                <Container className="smallScreen">
                    {entryImage}<br/>
                    {entryDetails}
                </Container>
            </div>

        );

    }
}

export default withTranslation('common')(CCartEntry);