import React, {Component} from 'react'

import './CListingDetails.css';
import {Card, Form} from 'react-bootstrap';
import Select from 'react-select'

import CListingAdminAction from './CListingAdminAction';
import CListingCustomization from './CListingCustomization';
import ApiV0 from '../services/ApiV0';
import EventEmitter from '../services/EventEmitter';
import {CConstants} from '../common/CConstants';
import CPrice from '../common/CPrice';
import {analyticsEvent} from '../core/AnalyticsManager';
import {withTranslation} from 'react-i18next';

import {decode} from 'html-entities';

const DEBUG_LISTING_DETAILS = false;

class CListingDetails extends Component {
    constructor(props) {
        super(props);
        const lang = ApiV0.getLang();
        this.state = this.getStateFromLang(lang);
    }

    getStateFromLang(lang = 'fr') {
        const productVariationKey = this.props.listing.has_variations === true ? this.getDefaultVariationKey(this.props.listing) : null;
        const productVariationKeyLabel = this.props.listing.has_variations ? this.getDefaultVariationKey(this.props.listing, lang) : null;
        const productVariationValues = productVariationKey ? this.getVariationValues(this.props.listing, productVariationKey, lang) : [];
        const defaultValue = null
        /*
        // select default variation
        var defaultValue = productVariationKey ? this.getDefaultVariationValue(this.props.listing, productVariationKey) : null;
        if (defaultValue !== null && this.props.onSelectVariation) { // default image
           var vImage = this.getVariationImage(this.props.listing, productVariationKey, defaultValue);
           this.props.onSelectVariation(productVariationKey, defaultValue, vImage, false);
        }
        */
        return {
            productVariationKey,
            productVariationKeyLabel,
            hasVariations: this.props.listing.has_variations === true && productVariationKey !== null,
            customizationConfig: this.props.listing.customization_config,
            hasCustomization: window.isSet(this.props.listing.customization_config),
            productVariationValues,
            currentValue: defaultValue,
            currentCustomization: null,
            updatingQuantity: false,
            showAdmin: false
        }
    }

    handleLangChange(event) {
        // console.log("lang has changed to", event.detail.newLang);
        const newState = this.getStateFromLang(ApiV0.getLang());
        newState.currentCustomization = null;
        this.setState(newState);
    }

    handleToggleAdminChange(event) {
        this.setState({showAdmin: !this.state.showAdmin});
    }

    handleSlideImage(event) {
        const userGenerated = true;
        const imageId = event?.detail?.imageId;
        const variationValue = this.getVariationValueFromImageId(imageId);
        if (window.isSet(variationValue)) {
            DEBUG_LISTING_DETAILS && console.log(`CListingDetails slide to imageId:${imageId} => variationValue:${variationValue}`);
            this.setListingVariantValue(variationValue, userGenerated)
        }
    }

    componentDidMount() {
        document.body.addEventListener(EventEmitter.CH_SET_LANG, this.handleLangChange.bind(this));
        document.body.addEventListener(EventEmitter.CH_TOGGLE_ADMIN, this.handleToggleAdminChange.bind(this));
        document.body.addEventListener(EventEmitter.CH_SLIDE_IMAGE, this.handleSlideImage.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener(EventEmitter.CH_SET_LANG, this.handleLangChange.bind(this));
        document.body.removeEventListener(EventEmitter.CH_TOGGLE_ADMIN, this.handleToggleAdminChange.bind(this));
        document.body.removeEventListener(EventEmitter.CH_SLIDE_IMAGE, this.handleSlideImage.bind(this));
    }

    addToCart() {
        const {t, title} = this.props;
        const hasCustomization = this.state.hasCustomization;
        const hasVariations = this.state.hasVariations;
        const listingId = this.props.listing.listing_id;
        const matchingCart = this.getMatchingCart();
        const isAlreadyInTheCart = (matchingCart && matchingCart.quantity > 0);
        const quantityInTheCart = isAlreadyInTheCart ? matchingCart.quantity : 0;
        var quantity = hasCustomization ? 1 :
            this.state.wantedQuantity ? 0 + this.state.wantedQuantity + quantityInTheCart :
                1 + quantityInTheCart;
        this.setState({updatingQuantity: true}, () => {
            ApiV0.addToCart(listingId, quantity, this.state.productVariationKey, this.state.currentValue, this.state.currentCustomization)
                .then((cart) => {
                    DEBUG_LISTING_DETAILS && console.log('add cart', cart);
                    this.props.onCartUpdated(`Article ${title} ${t('listing.details.cartAdded')}`);
                    const evt = hasCustomization ? `addCart  ${quantity} ${listingId} ${title} customized` :
                        hasVariations ? `addCart  ${quantity} ${listingId} ${title} ${this.state.productVariationKey} ${this.state.currentValue}` :
                            `addCart  ${quantity} ${listingId} ${title}`;
                    analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt);
                    this.setState({updatingQuantity: false, wantedQuantity: 1});
                })
                .catch((error) => {
                    console.error('add cart error:', error);
                    this.props.onCartError(t('listing.details.error.cantAdd'));
                    this.setState({updatingQuantity: false});
                })
        });
    }

    _(toDecode) {
        return decode(toDecode);
    }

    getFirstVariationKey(listing) {
        return Object.keys(listing.products[0].values)[0];
    }

    getVariationValueFromImageId(imageId) {
        const {listing} = this.props;
        for (const p of listing.products) {
            const productKeys = Object.keys(p.values);
            for (const pk of productKeys) {
                const pkValues = p.values[pk];
                const pkValuesKeys = Object.keys(pkValues);
                if (pkValues[pkValuesKeys[0]]?.image_id === imageId) {
                    return pkValuesKeys[0];
                }
            }
        }
    }

    getDefaultVariationKey(listing, lang = null) {
        if (!listing.products || listing.products.length < 1) {
            return null;
        }
        const firstAvailable = listing.products.find(p => p.quantity > 0);
        if (lang === 'en' && firstAvailable && firstAvailable.en_values) {
            return Object.keys(firstAvailable.en_values)[0];
        }
        if (firstAvailable && firstAvailable.values) {
            return Object.keys(firstAvailable.values)[0];
        }
        return null;
    }

    getVariationValues(listing, key, lang = null) {
        const {t} = this.props;
        const unavailable = t('listing.details.variationUnavailable');
        const productWithThatKey = listing.products.filter(p => key in p.values || key in p.en_values);

        return productWithThatKey.map(p => {
            const values = key in p.values ? p.values : p.en_values;
            const value = Object.keys(values[key])[0];
            const i18label = value; // ETSY V3 NOT AVAILABLE
            const label = i18label + (p.quantity > 0 ? "" : ` (${unavailable})`);
            const isDisabled = (p.quantity < 1);
            const image_id = values[key][value]["image_id"];
            const price_raw = p.price_raw;
            const price_code = p.price_code;
            const quantityMax = p.quantityMax;
            const quantity = p.quantity;
            return {label, value, image_id, isDisabled, price_raw, price_code, quantity, quantityMax};
        });
    }

    getProducts(listing, variationKey, variationValue) {
        const varValues = this.getVariationValues(listing, variationKey);
        return varValues.find(v => v.value === variationValue);
    }

    getVariationImage(listing, key, value) {
        const selProduct = this.getProducts(listing, key, value);
        return selProduct !== undefined ? selProduct.image_id : null;
    }

    getMatchingProducts(listing) {
        return this.getProducts(listing, this.state.productVariationKey, this.state.currentValue);
    }

    getProductPrice(listing) {
        if (!this.state.currentValue) {
            return null;
        }
        const matchingProducts = this.getMatchingProducts(listing);
        return (!matchingProducts || !window.isSet(matchingProducts.price_raw)) ?
            null : (<CPrice price={matchingProducts.price_raw} currency={matchingProducts.price_code}/>);
    }

    getVariationProductQuantity(listing) {
        if (!this.state.currentValue || this.state.currentValue === "null") {
            return null;
        }
        const matchingProducts = this.getMatchingProducts(listing);
        return matchingProducts.quantityMax ? Math.min(matchingProducts.quantity, matchingProducts.quantityMax) :
            matchingProducts.quantity;
    }

    setListingVariantValue(variantValue, userGenerated) {
        DEBUG_LISTING_DETAILS && console.info("setVariantValue", variantValue, userGenerated);
        this.setState({currentValue: variantValue})
        if (this.props.onSelectVariation) {
            const vImage = this.getVariationImage(this.props.listing, this.state.productVariationKey, variantValue);
            this.props.onSelectVariation(this.state.productVariationKey, variantValue, vImage, userGenerated);
        }
    }

    handleChangeFromVariationBox(selectedOption) {
        this.setListingVariantValue(selectedOption.value, true);
    }

    handleCustomizationChange(newCustomization) {
        const currentCustomization = window.isSet(newCustomization) ? JSON.stringify(newCustomization) : null;
        this.setState({currentCustomization});
    }

    getMatchingCart() {
        return this.props.cartFindEntry(this.props.listing.listing_id, this.state.productVariationKey, this.state.currentValue, this.currentCustomization);
    }

    getMatchingCustomizationCount() {
        return this.props.cartEntryQuantity(this.props.listing.listing_id);
    }

    changeWantedQuantity(event) {
        DEBUG_LISTING_DETAILS && console.info("changeWantedQuantity", event.target.value);
        this.setState({wantedQuantity: parseInt(event.target.value, 10)});
    }

    toggleAdmin() {
        EventEmitter.emitToggleAdmin()
    }

    render() {
        const {t, isAdmin, title} = this.props;
        const hasVariations = this.state.hasVariations;
        const hasCustomization = this.state.hasCustomization;
        const customizationConfig = this.state.customizationConfig;
        const showAdmin = this.state.showAdmin;
        const listing = this.props.listing;
        const productCurrentPrice = this.getProductPrice(listing);
        const productCurrentQuantity = hasVariations && !window.isSet(productCurrentPrice) ? 0 :
            hasVariations ? Math.min(this.getVariationProductQuantity(listing), listing.quantity) :
                listing.quantity;
        const isConnected = this.props.isConnected;
        const matchingCart = this.getMatchingCart();
        const matchingCustomizationCount = this.getMatchingCustomizationCount();
        const matchingCartQuantity = hasCustomization ? matchingCustomizationCount :
            matchingCart && window.isSet(matchingCart.quantityMax) ? Math.min(matchingCart.quantity, matchingCart.quantityMax) :
                matchingCart ? matchingCart.quantity : 0;
        const isAlreadyInTheCart = (matchingCart && matchingCartQuantity > 0);
        const quantityInTheCart = isAlreadyInTheCart ? matchingCartQuantity : 0;
        const stayingQuantity = isAlreadyInTheCart ? productCurrentQuantity - quantityInTheCart : productCurrentQuantity;
        const quantityToSet = window.isSet(this.state.wantedQuantity) ? this.state.wantedQuantity : 1;
        const curSelectedVal = this.state.productVariationValues.find(f => f.value === this.state.currentValue);
        const canAddMoreProduct = stayingQuantity >= 1;
        const submitDisabled = (!canAddMoreProduct && stayingQuantity !== 1);
        const customConfig = customizationConfig ? JSON.parse(customizationConfig) : null;
        DEBUG_LISTING_DETAILS && console.log({
            matchingCart, isConnected, isAlreadyInTheCart, productCurrentQuantity,
            stayingQuantity, hasVariations, hasCustomization
        });

        const listingTitle = (
            <div className="clistingTitle">
                <span itemProp="name">{title}</span>
            </div>
        );

        const variationSelectBox = hasVariations ? (
            <div className="clistingVariationBox">
                {this.state.productVariationKeyLabel} :
                <Select placeholder={t('listing.choose')}
                        options={this.state.productVariationValues}
                        value={curSelectedVal} onChange={this.handleChangeFromVariationBox.bind(this)}
                        isOptionDisabled={(option) => option.isDisabled}
                        isSearchable={false}/>
            </div>
        ) : null;

        const variationCurrentPrice = hasVariations && productCurrentPrice ? (
            <div className="clistingPriceBox">
                {/* <div className="clistingPriceLabel">{t('listing.details.price')}</div> */}
                <div className="clistingPrice">{productCurrentPrice}</div>
            </div>
        ) : null;

        const listingPrice = hasVariations ? (
            <div>
                {variationCurrentPrice}
                {variationSelectBox}
            </div>
        ) : hasCustomization && stayingQuantity > 0 ? (
            <div className="clistingPriceBox">
                <div className="clistingPrice"><CPrice price={listing.price} currency={listing.currency_code}/></div>
                <CListingCustomization config={customConfig} value={listing.customization}
                                       onChange={this.handleCustomizationChange.bind(this)}/>
            </div>
        ) : (
            <div className="clistingPriceBox">
                {/* <div className="clistingPriceLabel">{t('listing.details.price')}</div> */}
                <div className="clistingPrice"><CPrice price={listing.price} currency={listing.currency_code}/></div>
            </div>
        )

        let listingCartButton = null;
        const listingCartNotice = isAlreadyInTheCart ?
            (<div className="detailsTips">
                {quantityInTheCart} {t('listing.details.inYour')} <a href="/cart"
                                                                     className="txt">{t('listing.details.cart')}</a>
            </div>) : null;

        if (hasCustomization && productCurrentQuantity > 0 && canAddMoreProduct && this.state.currentCustomization === null) {// personnalisez
            listingCartButton = (<div className="detailsTips">{t('listing.details.doCustomize')}</div>);
        } else if (hasVariations && this.state.currentValue === null) {// choisissez
            listingCartButton = null;
        } else if (productCurrentQuantity <= 0 || !canAddMoreProduct) {
            listingCartButton = (<div className="detailsTips">{t('listing.details.productUnavailable')}</div>);
        } else {// productCurrentQuantity > 0
            listingCartButton = this.state.updatingQuantity === false
            && ((!isAlreadyInTheCart) || canAddMoreProduct) ? (
                <>
                    <div className="clistingButtonBox">
                        <Form>
                            <div className="clistingPriceBox">
                                {!hasCustomization && canAddMoreProduct ? (<>
                                    {t('listing.details.quantity')}&#160;:<br/>
                                    <Form.Select value={quantityToSet}
                                                 onChange={this.changeWantedQuantity.bind(this)}
                                                 style={{width: 80}}
                                    >
                                        {[...Array(stayingQuantity).keys()].map((val, index) => {
                                            return (
                                                <option key={index} index={index}
                                                        value={val + 1}>{val + 1}</option>)
                                        })}
                                    </Form.Select>
                                </>) : null}
                                &#160;
                                {!isAlreadyInTheCart || canAddMoreProduct ? (
                                    <button type="button"
                                            className="btn btn-secondary btn-cart"
                                            onClick={this.addToCart.bind(this)}
                                            disabled={submitDisabled}
                                            title={t('listing.details.doAddToCart')}
                                    >{t('listing.details.doAddToCart')}</button>
                                ) : null}
                            </div>
                        </Form>
                    </div>
                </>
            ) : null;
        }
        return (
            <>
                <Card style={{width: '18rem'}} className="clistingDetails">
                    {listingTitle}
                    {listingPrice}
                    {listingCartButton}
                    {listingCartNotice}
                    {isAdmin && (<div className="toggleAdmin" onClick={() => this.toggleAdmin()}>admin</div>)}

                    {/* DEBUG
                    <pre>
                    quantityInTheCart:{quantityInTheCart}<br/>
                    productCurrentQuantity:{productCurrentQuantity}<br/>
                    stayingQuantity:{stayingQuantity}<br/>
                    isAlreadyInTheCart:{isAlreadyInTheCart ? "YES" : "NO"}<br/>
                    canAddMoreProduct:{canAddMoreProduct ? "YES" : "NO"}<br/>
                    </pre> */}

                    <CListingAdminAction
                        isAdmin={showAdmin && isAdmin}
                        listing={listing}
                    />
                    {/* JSON.stringify(this.state) */}
                </Card>
            </>
        );
    }
}

export default withTranslation('common')(CListingDetails);